// Toggle mobile nav overlay //
$(document).ready(function() {
	$(".nav-toggle").click(function() {
		$(".nav-mobile").toggleClass("nav-visible");
		$("html").toggleClass("no-scroll");
	});
});

// Toggle project category dropdown button caret on mobile //
$(document).ready(function() {
	$(".mobile-cat-list-btn").click(function() {
		$(this).toggleClass("mobile-cat-list-btn-active");
	});
});
	
// Owl Carousel //	
$(document).ready(function(){
	$('.owl-carousel').owlCarousel({
	    loop:true,
	    margin:5,
	    nav:true,
		navText: [
			"<i class='fa fa-arrow-circle-o-left'></i>",
			"<i class='fa fa-arrow-circle-o-right'></i>"
		],
	    responsive:{
	        0:{
	            items:2
	        },
	        600:{
	            items:3
	        },
	        1000:{
	            items:3
	        }
	    }
	});
});

// Scroll to top button visibility //
$(window).scroll(function() {
	if ($(this).scrollTop() > 0){  
    	$('.top-return').show();
  	}
  	else{
    	$('.top-return').hide();
  	}
});
	
// Animate scroll to top //
$(document).ready(function(){
	$('.top-return a').click(function(){
		$('html, body').animate({scrollTop : 0},800);
		return false;
	});
});

// Fade in top intro headline //
$(document).ready(function(){
	$('.js .intro-text-gradient').animate({opacity: 1},500);
});